<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-component-text comp-id="content" :component="block.content"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-word-page #hot-reload-debug
export default {
    name: `cms-block-word-page`,
    components: {CmsComponentText, CmsBlock},
    extends: CmsBlock
}
</script>

<style lang="scss" scoped>
.cms-block-word-page {
    .cms-component-text {
        ::v-deep .cms-text {
            min-height: 300px;
        }
    }
}
</style>
